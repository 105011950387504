import React, { useEffect, useState } from "react"
import Layout from "../components/Layout/Layout"
import { graphql } from "gatsby"
import { Box, Flex } from "@chakra-ui/layout"
import { Spinner, Button, Image } from "@chakra-ui/react"
import { Trans } from "gatsby-plugin-react-i18next"
import { useMediaQuery } from "react-responsive"

const photoFilters = [
  { title: "Sports", id: 0 },
  { title: "Mode", id: 1 },
  { title: "Music", id: 2 },
]

const PhotoPage = ({ data }) => {
  const [loading, setLoading] = useState(true)
  const [sportsPhotos, setSportsPhotos] = useState([])
  const [fashionPhotos, setFashionPhotos] = useState([])
  const [musicPhotos, setMusicPhotos] = useState([])
  const [actualPhotoFilter, setActualPhotoFilter] = useState(0)
  const isBigScreen = useMediaQuery({ query: "(min-height: 800px)" })

  useEffect(() => {
    const fetchImage = async () => {
      await data.allFile.edges.forEach(element => {
        if (element.node.relativeDirectory.split("/")[1] === "sports") {
          let newArray = sportsPhotos
          newArray.push(element.node.childImageSharp.original)
          newArray.sort(function (a, b) {
            console.log(a.src.substring(8, a.src.lastIndexOf("-")))
            console.log(b.src.substring(8, b.src.lastIndexOf("-")))
            return (
              a.src.substring(8, a.src.lastIndexOf("-")) -
              b.src.substring(8, b.src.lastIndexOf("-"))
            )
          })
          return setSportsPhotos(newArray)
        } else if (element.node.relativeDirectory.split("/")[1] === "fashion") {
          let newArray = fashionPhotos
          newArray.push(element.node.childImageSharp.original)
          newArray.sort(function (a, b) {
            console.log(a.src.substring(8, a.src.lastIndexOf("-")))
            console.log(b.src.substring(8, b.src.lastIndexOf("-")))
            return (
              a.src.substring(8, a.src.lastIndexOf("-")) -
              b.src.substring(8, b.src.lastIndexOf("-"))
            )
          })
          return setFashionPhotos(newArray)
        } else if (element.node.relativeDirectory.split("/")[1] === "music") {
          let newArray = musicPhotos
          newArray.push(element.node.childImageSharp.original)
          newArray.sort(function (a, b) {
            console.log(a.src.substring(8, a.src.lastIndexOf("-")))
            console.log(b.src.substring(8, b.src.lastIndexOf("-")))
            return (
              a.src.substring(8, a.src.lastIndexOf("-")) -
              b.src.substring(8, b.src.lastIndexOf("-"))
            )
          })
          return setMusicPhotos(newArray)
        }
      })
    }

    fetchImage()

    setLoading(false)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (typeof window !== undefined) {
      const scrollContainer = document.getElementById("scroll-container")

      if (scrollContainer) {
        scrollContainer.addEventListener("wheel", evt => {
          evt.preventDefault()
          scrollContainer.scrollLeft += evt.deltaY
        })
      }
    }
  })

  return (
    <Layout navbarBottom pageTitle="photos">
      <Box w="100%" m="0 auto">
        {loading ? (
          <Spinner color="blue.500" m="0 auto" />
        ) : (
          <>
            <Flex align="center" justify="center">
              {photoFilters.map((e, i) => {
                return (
                  <Button
                    bgColor="transparent"
                    color={actualPhotoFilter === i ? "white" : "whiteAlpha.500"}
                    fontFamily="sans-serif"
                    fontWeight={300}
                    fontSize={[12, 12, 15, 15]}
                    onClick={() => {
                      document.getElementById("scroll-container").scrollLeft = 0

                      setActualPhotoFilter(e.id)
                    }}
                    _hover={{
                      color: "whiteAlpha.900",
                    }}
                    _active={{
                      bgColor: "transparent",
                    }}
                    key={i}
                    mx="xs"
                    mb="sm"
                    size="sm"
                    _focus={{
                      boxShadow: "none",
                    }}
                  >
                    <Trans>{e.title}</Trans>
                  </Button>
                )
              })}
            </Flex>
            <Box
              id="scroll-container"
              overflowX="scroll"
              overflowY="hidden"
              className="photos-container"
              mt={[0, 0, isBigScreen ? "md" : "xs", isBigScreen ? "md" : "xs"]}
            >
              <Flex align="center" flexDir="row">
                {actualPhotoFilter === 0
                  ? sportsPhotos.map((e, i) => {
                      return (
                        <Image
                          src={e.src}
                          key={i}
                          objectFit="cover"
                          height={
                            isBigScreen
                              ? [290, 290, 400, 400]
                              : [275, 275, 370, 370]
                          }
                          mx="sm"
                        />
                      )
                    })
                  : actualPhotoFilter === 1
                  ? fashionPhotos.map((e, i) => {
                      return (
                        <Image
                          src={e.src}
                          key={i}
                          objectFit="cover"
                          height={
                            isBigScreen
                              ? [290, 290, 400, 400]
                              : [275, 275, 370, 370]
                          }
                          mx="sm"
                        />
                      )
                    })
                  : musicPhotos.map((e, i) => {
                      return (
                        <Image
                          src={e.src}
                          key={i}
                          objectFit="cover"
                          height={
                            isBigScreen
                              ? [290, 290, 400, 400]
                              : [275, 275, 370, 370]
                          }
                          mx="sm"
                        />
                      )
                    })}
              </Flex>
            </Box>
          </>
        )}
      </Box>
    </Layout>
  )
}

export default PhotoPage

export const query = graphql`
  query AssetsPhotos($language: String!) {
    allFile(
      filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          base
          childImageSharp {
            original {
              height
              width
              src
            }
          }
          relativeDirectory
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
